<script setup lang="ts">
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseToggle from '/~/components/base/toggle/base-toggle.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useLocalization } from '/~/composables/localization'
import {
  useStatementsListItem,
  StatementAccount,
  Statement,
} from '/~/composables/statements'
import ItemDate from '/~/templates/bill-payments/views/payments/make/components/select-statement/content/item/date.vue'
import ItemNotification from '/~/templates/bill-payments/views/payments/make/components/select-statement/content/item/notification.vue'

const props = withDefaults(
  defineProps<{
    item?: Statement | null
    account?: StatementAccount | null
  }>(),
  {
    item: null,
    account: null,
  }
)

const { withAccessCheck, isCheckingIdentityVerification } = useAccess()

const { translate } = useLocalization()

const {
  title,
  subtitle,
  subtotal,
  clientName,
  clientNumber,
  statementAccount,

  isBlocked,
  isRejected,
  isDirectDebitDisabled,
  isPaymentInitializing,
  isAutoPayToggled,
  isToggleBlocked,

  showEditAccountModal,
  showRemoveAccountModal,
  changeToggle,
  onPay,
} = useStatementsListItem(props)

function handleChangeToggle(value: boolean) {
  if (isAutoPayToggled.value) {
    withAccessCheck({
      callback: () => {
        changeToggle(value)
      },
      reject: () => {
        isAutoPayToggled.value = false
      },
    })
  } else {
    changeToggle(value)
  }
}
</script>

<template>
  <div class="mb-8 block rounded-xl bg-white p-6 text-neutral-400">
    <div class="mb-5 flex justify-between space-x-[30px]">
      <div class="flex min-w-0 items-center space-x-4">
        <h3 class="divide-x truncate text-lg text-eonx-neutral-800">
          <span class="pr-2">{{ clientName }}</span>
          <span class="pl-2">{{ clientNumber }}</span>
        </h3>
        <div class="flex space-x-2 divide-x">
          <button
            class="flex items-center whitespace-nowrap hover:opacity-80"
            :class="{
              'text-primary-darken': !isRejected,
              'pointer-events-none text-neutral-400': isRejected,
            }"
            @click="showEditAccountModal"
          >
            <base-icon svg="heroicons/solid/pencil-square" :size="24" />
            <span>Edit</span>
          </button>
          <button
            class="flex items-center whitespace-nowrap pl-2 text-red-700 hover:opacity-80"
            @click="showRemoveAccountModal"
          >
            <base-icon svg="heroicons/solid/trash" :size="24" />
            <span>Remove</span>
          </button>
        </div>
      </div>
      <div
        class="flex items-center space-x-2.5 whitespace-nowrap"
        :class="[
          { 'text-eonx-neutral-800': !isDirectDebitDisabled },
          { 'text-neutral-400': isDirectDebitDisabled },
        ]"
      >
        <span>{{ translate('directDebit.text') }}</span>
        <base-toggle
          v-model="isAutoPayToggled"
          :disabled="
            isDirectDebitDisabled ||
            isToggleBlocked ||
            isCheckingIdentityVerification
          "
          @change="handleChangeToggle"
        />
      </div>
    </div>
    <div
      class="flex items-center space-x-3 border-t pt-5 transition-colors duration-150 ease-in-out"
    >
      <payee-icon :payee="item?.payee" />
      <div
        class="flex h-12 grow flex-col truncate lg:min-w-32"
        :class="[
          { 'justify-center': !subtitle },
          { 'justify-between': subtitle },
        ]"
      >
        <h4 class="text-base font-bold text-eonx-neutral-800" :title="title">
          {{ title }}
        </h4>
        <div
          v-if="subtitle"
          class="flex flex-wrap items-center text-sm text-eonx-neutral-600"
        >
          {{ subtitle }}
        </div>
      </div>
      <div class="flex space-x-14">
        <item-date :statement="item" />
        <div class="flex h-12 flex-col justify-between">
          <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
            Amount
          </p>
          <p class="text-eonx-neutral-800">
            {{ subtotal }}
          </p>
        </div>
        <div class="flex items-center">
          <base-button
            look="outlined-color"
            size="xs"
            rounded
            class="!h-8 !w-28"
            :disabled="isBlocked"
            :loading="isPaymentInitializing"
            @click="withAccessCheck({ callback: onPay })"
          >
            Pay now
          </base-button>
        </div>
      </div>
    </div>
    <item-notification :account="statementAccount" class="mt-5" />
  </div>
</template>
