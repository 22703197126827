<script>
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useStatementAccounts } from '/~/composables/statements'

export default {
  name: 'statements-manage-accounts-buttons',
  components: {
    BaseButton,
    BaseLoader,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add-account-clicked'],
  setup() {
    const router = useRouter()
    const { isStatementAccountsLoading, allowMultipleStatementAccounts } =
      useStatementAccounts()
    const { withAccessCheck, isCheckingIdentityVerification } = useAccess()

    const isDesktop = !ui.mobile

    function onAddAccountClicked() {
      router.push({
        name: 'statements-new-account',
        params: {
          type: 'new-account',
        },
      })
    }

    return {
      allowMultipleStatementAccounts,
      isStatementAccountsLoading,
      onAddAccountClicked,
      isDesktop,
      withAccessCheck,
      isCheckingIdentityVerification,
    }
  },
}
</script>

<template>
  <div
    v-if="allowMultipleStatementAccounts"
    class="flex flex-col items-center justify-center space-y-4 xs:shrink-0 xs:flex-row xs:space-x-4 xs:space-y-0 sm:justify-end"
  >
    <base-loader
      v-if="isStatementAccountsLoading"
      class="mx-auto xs:mx-0"
      size="xs"
    />
    <base-button
      data-testid="add-statement-account-button"
      class="w-full text-sm xs:max-w-56 sm:w-auto sm:max-w-none"
      :size="isDesktop ? 'sm' : null"
      :disabled="disabled || isCheckingIdentityVerification"
      :loading="isCheckingIdentityVerification"
      @click="withAccessCheck({ callback: onAddAccountClicked })"
    >
      + Add new
    </base-button>
  </div>
</template>
