<script>
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useLocalization } from '/~/composables/localization'
import { initDirectDebitsConfirmationModal } from '/~/composables/modals'
import { useProvider } from '/~/composables/provider'
import { useStatementAccounts } from '/~/composables/statements'
import LayoutView from '/~/templates/bill-payments/layouts/view/layout-view.vue'
import ManageAccountsButtons from '/~/templates/bill-payments/views/statements/list/components/manage-accounts-buttons.vue'
import StatementsList from './components/statements-list.vue'

export default {
  name: 'statements',
  components: {
    LayoutView,
    BaseAsidePage,
    ManageAccountsButtons,
    StatementsList,
  },
  setup() {
    const router = useRouter()
    const { isStatementAccountsEmpty, allowMultipleStatementAccounts } =
      useStatementAccounts()
    const { withAccessCheck } = useAccess()
    const { translate } = useLocalization()

    initDirectDebitsConfirmationModal()
    const { isBillPaymentsV15Template } = useProvider()

    function onAddAccountClicked() {
      withAccessCheck({
        callback: () => {
          router.push({
            name: 'statements-new-account',
            params: {
              type: 'new-account',
            },
          })
        },
      })
    }

    const enableAddButton = allowMultipleStatementAccounts

    return {
      enableAddButton,
      onAddAccountClicked,
      isStatementAccountsEmpty,
      isBillPaymentsV15Template,
      ui,
      translate,
    }
  },
}
</script>

<template>
  <base-aside-page
    v-if="ui.mobile"
    title="Statements"
    :no-padding="true"
    :action="enableAddButton ? onAddAccountClicked : undefined"
    :back="{
      name: 'home',
    }"
  >
    <div class="flex h-full flex-col overflow-auto bg-eonx-neutral-100 p-5">
      <statements-list />
    </div>
  </base-aside-page>

  <layout-view v-else>
    <div class="h-full w-full">
      <div
        class="flex h-full flex-col overflow-y-auto bg-eonx-neutral-50"
        :class="{
          'mr-6 rounded-t-3xl': !isBillPaymentsV15Template,
        }"
      >
        <div class="relative">
          <div
            v-if="!isStatementAccountsEmpty"
            class="sticky top-0 z-1 mb-8 flex items-start justify-between bg-eonx-neutral-50"
          >
            <h2 class="text-2xl text-eonx-neutral-800">
              Accounts {{ translate('common.and') }} statements
            </h2>
            <manage-accounts-buttons v-if="enableAddButton" />
          </div>
          <statements-list />
        </div>
      </div>
    </div>
  </layout-view>
</template>
